import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import intersect from '@alpinejs/intersect';
import focus from '@alpinejs/focus';
import swipePlugin from 'alpinejs-swipe';

window.Alpine = Alpine;

Alpine.plugin(swipePlugin);
Alpine.plugin(collapse);
Alpine.plugin(intersect);
Alpine.plugin(focus);
